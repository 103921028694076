import React from 'react';

import { newIdentifiant } from '@jvs-group/jvs-mairistem-tools';
import { FicheBar } from '@jvs-group/jvs-mairistem-fiche';
import { Menu } from '@jvs-group/jvs-mairistem-composants';

import { useUploadPjs } from '@jvs-group/jvs-mairistem-comptabilite';
import projetService from '@/src/services/projetService';

interface ProjetFicheProps {
  identifiant: number,
  onSaved: any,
}

export const handleCreatingFicheProjet = (data) => ({
  ...data,
  composants: [
    {
      identifiant: newIdentifiant(),
    },
  ],
});

const ProjetFicheBarConsumer = (props : ProjetFicheProps) => {
  const {
    identifiant, onSaved, ...handlers
  } = props;
  const uploadPjs = useUploadPjs(identifiant);

  const handleSaved = (data, status) => {
    onSaved(data, status);
    uploadPjs(data?.identifiant);
  };

  return (
    <Menu
      attached
      className="ficheBar"
      secondary
    >
      <FicheBar
        key="ficheProjet"
        type="projet"
        identifiant={identifiant}
        service={projetService}
        canDraft={false}
        onSaved={handleSaved}
        onCreating={handleCreatingFicheProjet}
        {...handlers}
      />
    </Menu>
  );
};

export default ProjetFicheBarConsumer;
