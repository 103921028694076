import { Input } from '@jvs-group/jvs-mairistem-composants';
import { ListeTable } from '@jvs-group/jvs-mairistem-liste';
import React from 'react';
import { Etiquette } from '@/src/interfaces/etiquette';

interface EtiquetteRowProps {
  etiquette: Etiquette,
  rowProps: any,
  utilities: any,
}

const EtiquetteRow = ({
  etiquette,
  rowProps,
  utilities,
} : EtiquetteRowProps) => {
  const handleChange = (e, { name, value }) => {
    utilities?.onChange({ [name]: value });
  };

  return (
    <ListeTable.Row
      key={etiquette?.identifiant}
      {...rowProps}
    >
      <ListeTable.Cell name="libelle" stretched>
        <Input
          name="libelle"
          value={etiquette?.libelle}
          onChange={handleChange}
          fluid
          transparent
          {...{
            disableFocus: utilities?.disableFocus,
            autoFocus: utilities?.autoFocus,
            disableShiftTabulation: utilities?.disableShiftTabulation,
          }}
        />
      </ListeTable.Cell>
    </ListeTable.Row>
  );
};

export default EtiquetteRow;
