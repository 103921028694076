import React from 'react';
import { Form, TextArea } from '@jvs-group/jvs-mairistem-composants';
import { Panel } from '@jvs-group/jvs-mairistem-finances-utils';

const ResumeComponent = ({
  data,
  handlePin,
  onChange,
  panel,
}) => {
  const handleChange = (e, { name, value }) => onChange?.({ [name]: value });

  return (
    <Panel panel={panel} onPin={handlePin}>
      <Form>
        <Form.Field
          key="resume"
          name="resume"
          value={data?.resume ?? ''}
          fluid
          // @ts-expect-error
          control={TextArea}
          onChange={handleChange}
          data-testid="resumeProjet"
          rows={4}
        />
      </Form>
    </Panel>
  );
};

export default ResumeComponent;
