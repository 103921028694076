import React from 'react';
import { Container, Segment } from '@jvs-group/jvs-mairistem-composants';
import SubventionListeRiche from '@/src/components/Dashboard/SubventionListeRiche/SubventionListeRiche';

const Dashboard = () => (
  <Container as={Segment} attached className="dashboard">
    <SubventionListeRiche />
  </Container>
);

export default Dashboard;
