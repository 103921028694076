import { BaseProfilEcriture } from '@jvs-group/jvs-mairistem-finances-utils';
import ResumePanel from '@/src/classes/Panel/ResumePanel';
import ImpactCollectivitePanel from '@/src/classes/Panel/ImpactCollectivitePanel';
import ImpactEnvironnementPanel from '@/src/classes/Panel/ImpactEnvironnementPanel';

class BaseProfilEcritureProjet extends BaseProfilEcriture {
  constructor() {
    super();
    this.panels = [
      new ResumePanel(false),
      new ImpactCollectivitePanel(false),
      new ImpactEnvironnementPanel(false),
    ];
  }
}

export default BaseProfilEcritureProjet;
