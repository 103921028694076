import { ListeContainer } from '@jvs-group/jvs-mairistem-liste';
import React from 'react';

import projetService from '@/src/services/projetService';
import ProjetListe from '@/src/components/ProjetListe/ProjetListe';

const ProjetListeContainer = () => (
  <ListeContainer
    type="projets"
    contains="liste"
    persistState={false}
    // @ts-expect-error
    service={projetService}
  >
    {/* @ts-expect-error */}
    <ProjetListe />
  </ListeContainer>
);

export default ProjetListeContainer;
