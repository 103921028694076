import React from 'react';
import { ListeContainer } from '@jvs-group/jvs-mairistem-liste';

import subventionService from '@/src/services/subventionService';
import SubventionListe from '@/src/components/SubventionListe/SubventionListe';

const SubventionListeContainer = () => (
  <ListeContainer
    type="subventions"
    contains="liste"
    persistState={false}
    // @ts-expect-error
    service={subventionService}
  >
    <SubventionListe />
  </ListeContainer>
);

export default SubventionListeContainer;
