import _, { forEach } from 'lodash';
import ConditionVersementError from '@/src/errors/conditionVersementError';

export const ETAT_COND_VERST_DEMANDE = 'VD';
export const ETAT_COND_VERST_ADEMANDER = 'VAD';
export const ETAT_COND_EN_ALERTE = 'A';
export const ETAT_COND_HORS_DELAIS = 'HD';
export const ETAT_COND_N_REMPLIS = 'NR';

export const getLibelleEtatCondition = (condition) => {
  let etat = condition?.etat;
  const today = new Date();

  today.setHours(0, 0, 0);
  if (condition?.dateButoire !== null && new Date(condition?.dateButoire) < today) {
    etat = ETAT_COND_HORS_DELAIS;
  } else if (condition?.dateAlerte !== null && new Date(condition?.dateAlerte) <= today) {
    etat = ETAT_COND_EN_ALERTE;
  }

  switch (etat) {
    case ETAT_COND_VERST_DEMANDE:
      return 'Versement demandé';
    case ETAT_COND_VERST_ADEMANDER:
      return 'Versement à demander';
    case ETAT_COND_EN_ALERTE:
      return 'En alerte';
    case ETAT_COND_HORS_DELAIS:
      return 'Hors délais';
    case ETAT_COND_N_REMPLIS:
      return 'Conditions non remplies';
    default:
      return 'Inconnu';
  }
};

/* Methode qui renvoit le pourcantage cumule d'une condition en fonction de son montant */
export const getPourcentageCumuleSubvention = (
  montant,
  montantSubvention,
  montantPrecedentesConditions,
) => (((montant + montantPrecedentesConditions) * 100) / montantSubvention);

/* Methode qui recalcul les pourcentages de la condition après maj du montant de celle-ci */
export const handleMontantChangeImpact = (montant, montantSubvention, montantPrecedentesConditions) => ({
  montant,
  pourcentageSubvention: (montant * 100) / montantSubvention,
  pourcentageCumuleSubvention: getPourcentageCumuleSubvention(
    montant,
    montantSubvention,
    montantPrecedentesConditions,
  ),
});

/* Methode qui recalcul le montant et pourcentage cumulé de la subvention après maj du pourcentage de la subvention */
export const handlePourcentageChangeImpact = (
  pourcentageSubvention,
  montantSubvention,
  montantPrecedentesConditions,
) => {
  const montant = ((pourcentageSubvention || 0) * montantSubvention) / 100;
  return {
    montant,
    pourcentageCumuleSubvention: getPourcentageCumuleSubvention(
      montant,
      montantSubvention,
      montantPrecedentesConditions,
    ),
  };
};

/* Methode qui recalcul le montant et pourcentage de la subvention après maj du pourcentage cumulé */
export const handlePourcentageCumuleChangeImpact = (
  pourcentageCumuleSubvention,
  montantSubvention,
  montantPrecedentesConditions,
) => {
  const montant = (((montantSubvention * pourcentageCumuleSubvention) / 100) - montantPrecedentesConditions);
  return {
    montant,
    pourcentageSubvention: (montant * 100) / montantSubvention,
  };
};

/* Methode qui sert a recalculer le montants des conditions apres changement du montant de la subvention */
export const handleMntAttribueChange = (conditionsVersements, mntAttribue, previousMontantAttribue) => {
  if (conditionsVersements.length === 0) {
    return null;
  }

  // montant cumule des conditions avant recalcul
  let previousMontantConditionCumule = 0;
  // montant cumule des conditions après recalcul
  let montantConditionCumule = 0;
  return conditionsVersements?.map((condition) => {
    let update = {};
    /* Si la condition est validée alors on ne veut pas modifier le montant
        On se base donc sur celui-ci pour recalculer les pourcentages */
    if (condition.etat === ETAT_COND_VERST_DEMANDE) {
      update = handleMontantChangeImpact(
        condition.montant,
        mntAttribue,
        montantConditionCumule,
      );
    } else {
      /* On recalcul le montant avec l'ancien pourcentage cumulé pour qu'il reste inchangé */
      const cumul = getPourcentageCumuleSubvention(
        condition.montant,
        previousMontantAttribue,
        previousMontantConditionCumule,
      );

      update = handlePourcentageCumuleChangeImpact(
        cumul,
        mntAttribue,
        montantConditionCumule,
      );
    }

    const updatedCondition = { ...condition, ...update };
    previousMontantConditionCumule += condition.montant;
    montantConditionCumule += updatedCondition.montant;
    return updatedCondition;
  });
};

export const validateStepCaracteristique = (versement) => {
  const result = { error: null, validated: false };

  if (_.isNil(versement?.libelle) || versement?.libelle.length === 0) {
    result.error = ConditionVersementError.LIBELLE_REQUIRED;
    return result;
  }

  if (_.isNil(versement?.montant)
          || versement?.montant < 0) {
    result.error = ConditionVersementError.MONTANT_REQUIRED;
    return result;
  }

  if (_.isNil(versement?.pourcentageSubvention)
          || versement?.conditionpourcentageSubvention < 0
          || versement?.pourcentageSubvention > 100) {
    result.error = ConditionVersementError.BAD_POURCENTAGE;
    return result;
  }

  if (_.isNil(versement?.pourcentageCumuleSubvention)
          || versement?.pourcentageCumuleSubvention < 0
          || versement?.pourcentageCumuleSubvention > 100) {
    result.error = ConditionVersementError.BAD_POURCENTAGE_CUMULE;
    return result;
  }

  if (_.isNil(versement?.identifiantEntiteCompta)
          || versement.identifiantEntiteCompta === 0) {
    result.error = ConditionVersementError.BUDGET_REQUIRED;
    return result;
  }

  if (_.isNil(versement?.annee)
          || versement.annee === 0) {
    result.error = ConditionVersementError.EXERCICE_REQUIRED;
    return result;
  }

  result.validated = true;
  return result;
};

export const validateStepModalite = (
  montantPrecedentesConditions,
  montantCondition,
  dateButoire,
  montantSubvention,
) => {
  const result = { error: null, validated: false };
  const isSolde = montantPrecedentesConditions + montantCondition >= montantSubvention;

  if (isSolde && (_.isNil(dateButoire) || dateButoire.length === 0)) {
    result.error = ConditionVersementError.DATE_BUTOIRE_REQUIRED;
    return result;
  }

  result.validated = true;
  return result;
};

export const getMontantRealiseConditions = (conditionsVersements, realisationHT) => {
  let total = 0;
  forEach(conditionsVersements, (condition) => {
    forEach(condition?.mouvements, (mouvement) => {
      forEach(mouvement?.lignes, (ligne) => {
        if (mouvement?.sens === 'R'
          && mouvement?.codeBHM === 'H'
          && ligne.identifiantImputationVersement === condition?.imputationsVersements?.[0]?.identifiant) {
          total += realisationHT ? ligne?.mtht ?? 0 : ligne?.mttc ?? 0;
        }
      });
    });
  });

  return total;
};

export const getMontantPieceConditions = (mouvement, versement) => {
  const total = { ht: 0, ttc: 0 };
  forEach(mouvement?.lignes, (ligne) => {
    if (ligne?.identifiantImputationVersement === versement?.imputationsVersements?.[0]?.identifiant) {
      total.ht += ligne?.mtht ?? 0;
      total.ttc += ligne?.mttc ?? 0;
    }
  });

  return total;
};
