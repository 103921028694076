import React from 'react';

import { FicheBar } from '@jvs-group/jvs-mairistem-fiche';
import { useDispatcher } from '@jvs-group/jvs-mairistem-store';

import { useUploadPjs } from '@jvs-group/jvs-mairistem-comptabilite';
import { Menu } from '@jvs-group/jvs-mairistem-composants';
import subventionService from '@/src/services/subventionService';

interface SubventionFicheBarConsumerProps {
  identifiant: number,
  onSaving: any,
  onError: any,
  onSaved: any,
}

export const handleCreatingFicheSubvention = (data) => ({
  ...data,
  realisationHT: true,
});

const SubventionFicheBarConsumer = (props : SubventionFicheBarConsumerProps) => {
  const {
    identifiant,
    onSaving,
    onSaved,
    ...handlers
  } = props;

  const setErrors = useDispatcher(subventionService, 'ERROR');
  const uploadPjs = useUploadPjs(identifiant);

  const handleSaved = (data, status) => {
    onSaved(data, status);
    uploadPjs(data?.identifiant);

    setErrors({
      identifiant,
      fields: null,
    });
  };

  return (
    <Menu
      attached
      className="ficheBar"
      secondary
    >
      <FicheBar
        {...handlers}
        type="subvention"
        service={subventionService}
        identifiant={identifiant}
        canDraft={false}
        onSaving={onSaving}
        onSaved={handleSaved}
        onCreating={handleCreatingFicheSubvention}
      />
    </Menu>
  );
};

export default SubventionFicheBarConsumer;
