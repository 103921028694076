import React from 'react';
import {
  Container,
  Form,
  Rating,
  TextArea,
} from '@jvs-group/jvs-mairistem-composants';
import { Panel } from '@jvs-group/jvs-mairistem-finances-utils';

const ImpactEnvironnementComponent = ({
  data,
  handlePin,
  onChange,
  panel,
}) => {
  const handleChange = (e, { name, value }) => onChange?.({ [name]: value });

  const handleRate = (e, { name, rating }) => onChange?.({ [name]: rating });

  return (
    <Panel panel={panel} onPin={handlePin}>
      <Container textAlign="center" className="ratingContainer">
        <Rating
          key="impactEnvironnement"
          name="impactEnvironnement"
          rating={data?.impactEnvironnement ?? 0}
          onRate={handleRate}
          maxRating={5}
          data-testid="impactEnvironnementProjet"
          size="massive"
          icon="star"
          clearable
        />
      </Container>
      <Form>
        <Form.Field
          key="impactEnvironnementTexte"
          name="impactEnvironnementTexte"
          value={data?.impactEnvironnementTexte ?? ''}
          fluid
          // @ts-expect-error
          control={TextArea}
          onChange={handleChange}
          data-testid="impactEnvironnementTexteProjet"
          rows={2}
        />
      </Form>
    </Panel>
  );
};

export default ImpactEnvironnementComponent;
