/* eslint-disable default-case */
import React from 'react';
import {
  Input,
  Grid,
  Currency,
  Form,
  Checkbox,
} from '@jvs-group/jvs-mairistem-composants';

import _ from 'lodash';
import { ImputationSearchInput } from '@jvs-group/jvs-mairistem-comptabilite';

import {
  handleMontantChangeImpact,
  handlePourcentageChangeImpact,
  handlePourcentageCumuleChangeImpact,
} from '@/src/utils/conditionVersement';
import { ConditionVersement } from '@/src/interfaces/conditionVersement';

const currentYear = new Date().getFullYear();
const CHAMP_MONTANT = 0;
const CHAMP_POURCENTAGE = 1;
const CHAMP_POURCENTAGE_CUMULE = 2;

interface EtapeCaracteristiqueProps {
  budgetOptions: any,
  conditionVersement: ConditionVersement,
  montantPrecedentesConditions: number,
  onLibelleDatalist: any,
  montantSubvention: number,
  onChange: any,
}

const EtapeCaracteristique = ({
  conditionVersement = null,
  montantPrecedentesConditions = null,
  onLibelleDatalist = null,
  montantSubvention = 0,
  onChange = null,
  budgetOptions = null,
} : EtapeCaracteristiqueProps) => {
  const budgetTypeOptions = [
    {
      key: 'BP',
      value: 'BP',
      text: 'Budget primitif',
    },
    {
      key: 'BS',
      value: 'BS',
      text: 'Budget supplémentaire',
    },
  ];

  const handleChangePourcentageSubvention = (e, value) => {
    onChange({ pourcentageSubvention: value, champCalcul: CHAMP_POURCENTAGE });
  };

  const handleChangePourcentageCumuleSubvention = React.useCallback((e, value) => {
    if (conditionVersement?.pourcentageCumuleSubvention !== value) {
      onChange({ pourcentageCumuleSubvention: value, champCalcul: CHAMP_POURCENTAGE_CUMULE });
    }
  }, [onChange, conditionVersement?.pourcentageCumuleSubvention]);

  React.useEffect(
    () => {
      if (montantSubvention !== 0 && conditionVersement?.montant !== null && montantPrecedentesConditions != null) {
        const champCalcul = conditionVersement?.champCalcul || CHAMP_MONTANT;
        let changes : any = null;
        switch (champCalcul) {
          case CHAMP_MONTANT:
            changes = handleMontantChangeImpact(
              conditionVersement?.montant,
              montantSubvention,
              montantPrecedentesConditions,
            );
            break;
          case CHAMP_POURCENTAGE:
            changes = handlePourcentageChangeImpact(
              conditionVersement?.pourcentageSubvention,
              montantSubvention,
              montantPrecedentesConditions,
            );
            break;
          case CHAMP_POURCENTAGE_CUMULE:
            changes = handlePourcentageCumuleChangeImpact(
              conditionVersement?.pourcentageCumuleSubvention,
              montantSubvention,
              montantPrecedentesConditions,
            );

            break;
        }

        onChange(
          {
            ...changes,
            imputationsVersements: [
              {
                ...(conditionVersement?.imputationsVersements?.[0] ?? {}),
                montant: changes.montant,
              },
            ],
          },
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      conditionVersement?.montant,
      conditionVersement?.pourcentageSubvention,
      conditionVersement?.pourcentageCumuleSubvention,
      montantPrecedentesConditions,
      onChange,
      conditionVersement?.champCalcul,
    ],
  );

  const handleChangeSelect = (e, { name, value }) => {
    onChange({
      [name]: value,
    });
  };

  const handleChangeInput = ({ target: { name, value } }) => {
    onChange({ [name]: value });
  };

  const anneeList = React.useMemo(() => {
    const result = [];
    for (let i = currentYear, max = currentYear + 3; i < max; i++) {
      result.push(i);
    }
    return result;
  }, []);

  const resetImputation = () => {
    const deletedImputationsVersements = [];
    if (conditionVersement.imputationsVersements?.length > 0
            && !_.isString(conditionVersement.imputationsVersements[0]?.identifiant)) {
      deletedImputationsVersements.push(conditionVersement.imputationsVersements[0]?.identifiant);
    }

    onChange({
      imputationsVersements: [],
      ...(deletedImputationsVersements?.length > 0 && { deletedImputationsVersements }),
    });
  };

  const handleChangeBudget = (e, data) => {
    resetImputation();
    handleChangeSelect(e, data);
  };

  const handleChangeExercice = ({ target: { valueAsNumber } }) => {
    onChange({ annee: Number.isNaN(valueAsNumber) ? null : valueAsNumber });
    resetImputation();
  };

  const handleImputationChange = (imputation) => {
    if (imputation === null) {
      resetImputation();
      return;
    }

    const imputationVersement = conditionVersement.imputationsVersements[0] ?? null;
    const imputationExtra = {
      section: imputation?.chapitre?.section,
      codeChapitre: imputation?.chapitre?.code,
      codeArticle: imputation?.article?.code,
      codeOperation: imputation?.operation?.code,
      codeFonction: imputation?.fonction?.code,
      codeVentilation: imputation?.ventilation?.code,
      codeAnalytique: imputation?.analytique?.code,
      natureBudgetaire: imputation?.chapitre?.natureBudgetaire,
    };

    // on est sur la meme annee alors on va garder seulement l'identifiant de l'imputation
    if (imputation?.exercice?.annee === conditionVersement.annee) {
      onChange({
        imputationsVersements: [
          {
            ...imputationVersement,
            libelle: imputation.libelle,
            identifiantImputation: imputation.identifiant,
            montant: conditionVersement.montant,
            chapitre: imputation?.chapitre,
            sens: imputation?.sens,
            ...imputationExtra,
          },
        ],
      });
    } else {
      onChange({
        imputationsVersements: [
          {
            ...imputationVersement,
            libelle: imputation.libelle,
            chapitre: imputation?.chapitre,
            montant: conditionVersement.montant,
            sens: imputation?.sens,
            identifiantImputation: null,
            ...imputationExtra,
          },
        ],
      });
    }
  };

  const handleChangeMontant = (e, value) => {
    onChange({
      montant: value,
      champCalcul: CHAMP_MONTANT,
    });
  };

  const getDefaultImputation = () => {
    const imputation = conditionVersement?.imputationsVersements?.[0];
    if (imputation) {
      return {
        libelle: imputation.libelle,
        sens: 'R',
        chapitre: {
          code: imputation.codeChapitre,
          section: imputation.section,
        },
      };
    }

    return null;
  };

  const handleCheckboxChange = (e, { name, checked }) => {
    onChange({ [name]: checked });
  };

  const imputationDisabled = (
    _.isNil(conditionVersement?.identifiantEntiteCompta) || _.isNil(conditionVersement?.annee)
  );

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16}>
          <Input
            key="libelleCondition"
            name="libelle"
            label="Intitulé"
            value={conditionVersement?.libelle}
            placeholder="Choissez une proposition ou saisissez librement un intitulé"
            onChange={handleChangeInput}
            fluid
            required
            list="libelleCondition"
            input={{ autocomplete: 'off' }}
          />
          <datalist id="libelleCondition">
            {_.map(onLibelleDatalist(), (libelle) => (
              <option>{libelle}</option>
            ))}
          </datalist>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>
          <Currency
            key="montant"
            name="montant"
            required
            label="Montant du versement"
            placeholder="Montant du versement"
            value={conditionVersement?.montant}
            className="flex-1"
            debounced={false}
            onChange={handleChangeMontant}
            fluid
          />
        </Grid.Column>

        <Grid.Column width={5}>
          <Currency
            key="pourcentageSubvention"
            name="pourcentageSubvention"
            label="Pourcentage de la subvention"
            placeholder="Pourcentage de la subvention"
            fluid
            percent
            value={conditionVersement?.pourcentageSubvention}
            onChange={handleChangePourcentageSubvention}
            debounced={false}
            percentMin={0}
            percentMax={100}
            description="Le taux doit être compris entre 0 et 100."
          />
        </Grid.Column>

        <Grid.Column width={5}>
          <Currency
            key="pourcentageCumuleSubvention"
            name="pourcentageCumuleSubvention"
            label="Pourcentage cumulé de la subvention"
            placeholder="Pourcentage cumulé de la subvention"
            onChange={handleChangePourcentageCumuleSubvention}
            debounced={false}
            value={conditionVersement?.pourcentageCumuleSubvention}
            fluid
            percent
            percentMin={0}
            percentMax={100}
            description="Le taux doit être compris entre 0 et 100."
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Form.Select
            placeholder="Budget"
            options={budgetOptions}
            value={conditionVersement?.identifiantEntiteCompta}
            name="identifiantEntiteCompta"
            onChange={handleChangeBudget}
            fluid
            required
            label="Budget"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={4}>
          <Input
            key="annee"
            name="annee"
            label="Exercice"
            value={conditionVersement?.annee}
            placeholder="Exercice"
            onChange={handleChangeExercice}
            type="number"
            required
            fluid
            list="exerciceList"
            data-testid="anneeCondition"
            input={{ autocomplete: 'off' }}
          />
          <datalist id="exerciceList">
            {anneeList.map((exercice) => (<option key={exercice}>{exercice}</option>))}
          </datalist>
        </Grid.Column>
        <Grid.Column width={12}>
          <Form.Select
            label="Inscription des prévisions au"
            placeholder="Budget"
            name="typeBudget"
            options={budgetTypeOptions}
            value={conditionVersement?.typeBudget}
            onChange={handleChangeSelect}
            fluid
            data-testid="typeBudgetCondition"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Form.Field>
                        <label>Imputation</label> {/* eslint-disable-line */}
            <ImputationSearchInput
              placeholder={imputationDisabled
                ? 'Veuillez selectionner un budget et un exercice.'
                : 'Commencez à saisir un article'}
              label="Imputation"
              name="imputation"
              onChange={handleImputationChange}
              disabled={imputationDisabled}
              identifiantEntite={conditionVersement?.identifiantEntiteCompta}
              annee={conditionVersement?.annee}
              sens="R"
              perPage={10}
              defaultValue={getDefaultImputation()}
              data-testid="imputationCondition"
              searchComplement={{
                withAnalytique: true,
                withArticle: true,
                withChapitre: true,
                withFonction: true,
                withOperation: true,
                withVentilation: true,
              }}
            />

          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Checkbox
            name="doitEngager"
            className="visibility"
            label="Ce versement doit faire l'objet d'un engagement de recette"
            checked={conditionVersement?.doitEngager}
            onChange={handleCheckboxChange}
            data-testid="doitEngagerCondition"
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default EtapeCaracteristique;
