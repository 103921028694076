enum ConditionVersementError {
  LIBELLE_REQUIRED = 'le libelle du versement est obligatoire',
  MONTANT_REQUIRED = 'Le montant est obligatoire',
  BAD_POURCENTAGE = 'le pourcentage de la subvention doit être compris entre 0 et 100',
  BAD_POURCENTAGE_CUMULE = 'le pourcentage cumulé de la subvention doit être compris entre 0 et 100',
  BUDGET_REQUIRED = 'Veuillez choisir un budget',
  EXERCICE_REQUIRED = 'Veuillez choisir un exercice',
  DATE_BUTOIRE_REQUIRED = 'La date butoire du versement est obligatoire',
}

export default ConditionVersementError;
