import React from 'react';
import { PanelClass } from '@jvs-group/jvs-mairistem-finances-utils';
import ResumeComponent from '@/src/components/Panel/ResumeComponent';
import { Projet } from '@/src/interfaces/projet';

class ResumePanel extends PanelClass {
  constructor(isMandatory: boolean) {
    super(isMandatory);
    this.code = 'RES';
    this.icon = 'text width';
  }

  getLibelle = () => 'Résumé';

  isEmpty = (data: Projet) => !data?.resume;

  getComponent = function getComponent(
    data: any,
    onChange: any,
    handlePin: any,
  ): React.ReactElement {
    return (
      <ResumeComponent
        data={data}
        handlePin={handlePin}
        onChange={onChange}
        panel={this}
      />
    );
  };
}

export default ResumePanel;
