import { isNil } from 'lodash';
import { Composant } from '@/src/interfaces/composant';

export const getMontantDepensesEligibles = (composants: Composant[], realisationHT: boolean) => {
  if (isNil(composants)) {
    return 0;
  }

  return composants?.reduce((acc, composant : Composant) => {
    let result = acc;
    const montantActualise = realisationHT ? composant?.montantActualiseHt ?? 0 : composant?.montantActualiseTtc;
    const montantInitial = realisationHT ? composant?.montantInitialHt ?? 0 : composant?.montantInitialTtc;
    const montantEstime = realisationHT ? composant?.montantEstimeHt ?? 0 : composant?.montantEstimeTtc;

    if (montantActualise > 0) {
      result += montantActualise;
    } else if (montantInitial > 0) {
      result += montantInitial;
    } else if (montantEstime > 0) {
      result += montantEstime;
    }

    return result;
  }, 0);
};

export const getAssietteEligible = (
  plafondEligible: number,
  composants: Composant[],
  realisationHT: boolean,
) => {
  const plafond = plafondEligible ?? 0;
  const depensesEligibles = getMontantDepensesEligibles(composants, realisationHT);
  if (plafond === 0) {
    return depensesEligibles;
  }

  return Math.min(plafond, depensesEligibles);
};
