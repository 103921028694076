// @ts-nocheck
import React from 'react';

import { FicheBarContainer } from '@jvs-group/jvs-mairistem-fiche';
import ProjetFicheBarConsumer, { handleCreatingFicheProjet } from '@/src/components/ProjetFiche/ProjetFicheBarConsumer';

import projetService from '@/src/services/projetService';

const ProjetFicheBar = () => (
  <FicheBarContainer
    type="projet"
    hasHistory={false}
    onCreating={handleCreatingFicheProjet}
    // @ts-expect-error
    service={projetService}
  >
    {({
      identifiant, ...handlers
    }) => (
      <ProjetFicheBarConsumer identifiant={identifiant} {...handlers} />
    )}
  </FicheBarContainer>
);

export default ProjetFicheBar;
