import _, { forEach } from 'lodash';
import { Projet } from '@/src/interfaces/projet';
import { Composant } from '@/src/interfaces/composant';
import { ProjetStatus } from '@/src/constants/projetStatus';

export const getMontantMouvementProjet = (mouvement: any, identifiantProjet : number, composants: Composant[]) => {
  const total = { ht: 0, ttc: 0 };
  if (mouvement?.codeBHM === 'H') {
    forEach(mouvement?.lignes, (ligne) => {
      if (ligne?.identifiantProjet === identifiantProjet
          && (_.isNil(composants) || composants?.find((c) => c.identifiant === ligne?.identifiantComposant))) {
        let montantHt = ligne?.mtht;
        let montantTtc = ligne?.mttc;

        if (mouvement?.sens === 'R') {
          montantHt *= -1;
          montantTtc *= -1;
        }

        if (mouvement?.bordereau?.typePes === '02'
                    || mouvement?.bordereau?.typePes === 'RE') {
          montantHt *= -1;
          montantTtc *= -1;
        }

        total.ht += montantHt;
        total.ttc += montantTtc;
      }
    });
  }

  return total;
};

export const getMontantRealiseProjet = (
  identifiantProjet,
  mouvements,
  realiseAnterieur,
  realisationHT,
  composants: Composant[],
) => {
  let total = realiseAnterieur ?? 0;
  _.forEach(mouvements, (mouvement) => {
    if (realisationHT) {
      total += getMontantMouvementProjet(mouvement, identifiantProjet, composants)?.ht ?? 0;
    } else {
      total += getMontantMouvementProjet(mouvement, identifiantProjet, composants)?.ttc ?? 0;
    }
  });

  return total;
};

export const getProgressProjet = (
  conditionsVersements,
  montantRealiseProjet,
  montantProjet: number,
) => {
  const steps = [100];
  conditionsVersements?.forEach((versement) => {
    if (!steps.includes(versement.pourcentageExecution)) {
      steps.push(versement.pourcentageExecution);
    }
  });

  return {
    total: montantProjet,
    progress: montantRealiseProjet,
    steps,
  };
};

export const getMontantProjet = (projet: Projet, modeHT: boolean) => {
  let montantProjet = 0;
  if (projet?.status === ProjetStatus.CONCEPTION) {
    montantProjet = projet?.composants?.reduce(
      (prev, next) => prev + (modeHT ? next.montantEstimeHt : next.montantEstimeTtc),
      0,
    );
  } else {
    montantProjet = modeHT ? projet?.montantHT : projet?.montantTTC;
  }

  return montantProjet;
};

export const getPrevisisonFinancement = (projet: Projet, modeHT: boolean) => {
  let totalSubvention = 0;
  projet?.subventions?.forEach((s) => {
    totalSubvention += projet?.status === ProjetStatus.CONCEPTION ? s.montantEnvisage : s.mntAttribue;
  });

  const montantProjet = getMontantProjet(projet, modeHT);

  return {
    autofinancement: (montantProjet ?? 0) - totalSubvention,
    externe: totalSubvention,
  };
};
