import {
  Chart, Partition, Settings, Tooltip, CustomTooltip as CT,
} from '@elastic/charts';

import React from 'react';
import '@elastic/charts/dist/theme_light.css';

interface PieData {
  type: string,
  percentage: string,
}

interface PieChartProps {
  id: string,
  data: PieData[],
  colors: any,
  labels: any,
}

const PieChart = ({
  id = '',
  labels = [],
  data = [],
  colors = [],
} : PieChartProps) => {
  // @ts-expect-error
  const CustomTooltip: CT = React.useCallback(({ items }) => {
    const item = items[0] ?? {};
    return (
      <div style={{ margin: '5px' }}>
        {labels[item?.seriesIdentifier.key]}
        {' '}
        :
        {' '}
        {item?.value}
        {' '}
        %
      </div>
    );
  }, [labels]);

  return (// @ts-expect-error
    <Chart size={{ height: 250, width: 250 }}>
      <Settings
        theme={{
          chartMargins: {
            top: 0, left: 0, bottom: 0, right: 0,
          },
          chartPaddings: {
            top: 0, left: 0, bottom: 0, right: 0,
          },
          partition: {
            fillLabel: {
              textColor: 'white',
            },

            linkLabel: {
              maximumSection: 50,
            },
          },
        }}
      />

      <Tooltip
        // @ts-expect-error
        body={CustomTooltip}
      />
      <Partition
        id={id}
        data={data}
        valueAccessor={(d) => Number(d.percentage)}
        layers={[
          {
            nodeLabel: () => ' ',
            groupByRollup: (d) => d.type,
            fillLabel: {
              minFontSize: 10,
              maxFontSize: 18,
            },
            shape: {
              fillColor: (type) => colors[type],
            },
          },
        ]}
      />
    </Chart>
  );
};

export default PieChart;
