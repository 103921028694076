import React from 'react';

import { FicheContainer } from '@jvs-group/jvs-mairistem-fiche';
import _ from 'lodash';
import { SemanticCOLORS } from '@jvs-group/jvs-mairistem-composants';
import { ErrorField } from '@jvs-group/jvs-mairistem-fiche/lib/ts/types';
import projetService from '@/src/services/projetService';
import ProjetFicheConsumer from '@/src/components/ProjetFiche/ProjetFicheConsumer';
import ProjetListeContainer from '@/src/components/ProjetListe/ProjetListeContainer';

interface ProjetFicheProps {
  liste?: () => any,
  color?: SemanticCOLORS,
}

const ProjetFiche = ({
  liste,
  color,
} : ProjetFicheProps) => {
  const sidebar = liste ? React.createElement(liste) : null;

  const handleApiError = (errors) : ErrorField[] => {
    if (errors?.code >= 500) {
      return [
        {
          id: null,
          message: 'Une erreur technique est survenue',
        },
      ];
    }

    return _.map(errors?.message, (error) => ({
      id: null,
      message: error?.message,
    }));
  };

  const handleValidate = (data) : ErrorField[] => {
    const {
      libelle = null,
      composants,
    } = data;

    const errors = [];
    if (_.isNil(libelle) || libelle.length === 0) {
      errors.push({ id: 'subventionsPercues-libelleProjet', message: 'Le libellé du projet est obligatoire' });
    }

    if (!_.isNil(composants?.find((c) => _.isNil(c.libelle)))) {
      errors.push(
        { id: 'subventionsPercues.composants.libelle', message: 'Le libellé des composants est obligatoire' },
      );
    }

    return errors;
  };

  return (
    <FicheContainer
      type="projet"
      service={projetService}
      onValidate={handleValidate}
      onApiError={handleApiError}
    >
      <ProjetFicheConsumer
        sidebars={{
          left: sidebar,
        }}
        color={color}
      />
    </FicheContainer>
  );
};

ProjetFiche.defaultProps = {
  liste: ProjetListeContainer,
  color: null,
};

export default ProjetFiche;
