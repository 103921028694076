import React from 'react';

import { FicheContainer } from '@jvs-group/jvs-mairistem-fiche';
import _ from 'lodash';
import type { SemanticCOLORS } from '@jvs-group/jvs-mairistem-composants';
import subventionService from '@/src/services/subventionService';
import SubventionFicheConsumer from '@/src/components/SubventionFiche/SubventionFicheConsumer';
import SubventionListeContainer from '@/src/components/SubventionListe/SubventionListeContainer';

interface SubventionFicheProps {
  liste?: any,
  color: SemanticCOLORS,
}

const SubventionFiche = ({
  liste,
  color,
  ...rest
} : SubventionFicheProps) => {
  const sidebar = liste ? React.createElement(liste) : null;

  const handleValidate = ({
    libelle = null,
    identifiantProjet = null,
    identifiantFinanceur = null,
  }) => {
    const errors = [];
    if (_.isNil(libelle) || libelle.length === 0) {
      errors.push({ id: 'subventionsPercues-libelle', message: 'Le libellé de la subvention est obligatoire' });
    }

    if (_.isNil(identifiantProjet) || identifiantProjet === 0) {
      errors.push(
        {
          id: 'subventionsPercues-identifiantProjet',
          message: 'Le projet de la subvention est obligatoire',
        },
      );
    }

    if (_.isNil(identifiantFinanceur) || identifiantFinanceur === 0) {
      errors.push(
        { id: 'subventionsPercues.identifiantFinanceur', message: 'Le financeur de la subvention est obligatoire' },
      );
    }

    return errors;
  };

  const handleApiError = (errors) => {
    if (errors?.code >= 500) {
      return {
        message: 'Une erreur technique est survenue',
      };
    }

    return _.map(errors?.message, (error) => ({
      message: error?.message,
    }));
  };

  return (
    <FicheContainer
      type="subvention"
      service={subventionService}
      /* @ts-expect-error */
      onValidate={handleValidate}
      color={color}
      /* @ts-expect-error */
      onApiError={handleApiError}
      {...rest}
    >
      {/* @ts-expect-error */}
      <SubventionFicheConsumer
        color={color}
        sidebars={{
          left: sidebar,
        }}
      />
    </FicheContainer>
  );
};

SubventionFiche.defaultProps = {
  liste: SubventionListeContainer,
};

export default SubventionFiche;
