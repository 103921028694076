import React from 'react';
import '@/src/style.less';
import { Segment, Tooltip } from '@jvs-group/jvs-mairistem-composants';
import { formatMontant } from '@jvs-group/jvs-mairistem-finances-utils';

interface Progress {
  total: number,
  progress: number,
  steps: Array<number>,
}

interface ProgressProps {
  color: string,
  progress: Progress,
}

const Progress = (
  {
    color = 'black',
    progress = { total: 0, progress: 0, steps: [] },
  } : ProgressProps,
) => {
  let percent = progress.total === 0 ? 0 : Math.floor((progress.progress * 100) / progress.total);
  percent = Math.min(Math.max(percent, 0), 100);
  return (
    <Tooltip
      type="information"
      message={(
        <div>
          <div>
            Avancement:
            {' '}
            {percent}
            %
          </div>
          <div>
            Total:
            {' '}
            {formatMontant(progress.total)}
          </div>
        </div>
              )}
      trigger={(
        <div className="wrapper">
          <div className="ui progress with-steps" style={{ width: '100%' }}>
            {/* @ts-expect-error */}
            <Segment basic inverted color={color} style={{ width: `${percent}%` }} />
          </div>
          {progress.steps && progress.steps.map((step) => (
            <div
              key={step}
              className={`step ${step <= percent ? '' : 'darkened'}`}
              style={{ left: `${step}%` }}
              data-step={`${step}%`}
            />
          ))}
        </div>
            )}
    />
  );
};

export default Progress;
