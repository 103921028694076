import { PieceJointe } from '@jvs-group/jvs-mairistem-comptabilite';
import {
  Fichiers,
  Gabarit, NATURES_DOCUMENTS, TYPE_GABARIT, TemplateRequestGet,
} from '@jvs-group/jvs-mairistem-finances-utils';
import { request } from '@jvs-group/jvs-mairistem-store';
import { isNil } from 'lodash';

export const getNatureFromGabarits = (gabarits : Gabarit[]) => {
  const natures = [];
  // recuperation depuis les gabarits
  gabarits?.forEach((gabarit) => {
    gabarit?.lignes?.forEach((ligne) => {
      // je recupere la nature de mon tableau resultat
      let nature = natures.find((n) => n.identifiant === ligne.codeNature);
      // doublon de nature
      if (nature && !nature.types.includes(gabarit.type)) {
        // multi type
        nature.types.push(gabarit.type);
      } else {
        // sinon je recupere la nature dans les constantes
        nature = NATURES_DOCUMENTS.find((n) => n.identifiant === ligne.codeNature);
        natures.push({ ...nature, types: [gabarit.type] });
      }
    });
  });
  return natures;
};

export const getLibelleTypeGabarit = (types: string[]) => {
  if (isNil(types)) {
    return '';
  }

  const libelles = types?.map((type) => {
    switch (type) {
      case TYPE_GABARIT.PROJET:
        return 'Projet';
      case TYPE_GABARIT.SUBVENTION_PERCUE:
        return 'Subvention';
      default:
        return '';
    }
  });
  return libelles.join(', ');
};

export const fetchConditionGabarits = (identifiantSubvention: number, identifiantProjet: number) => {
  const parameterSubvention = new URLSearchParams();
  parameterSubvention.append('identifiantFichierLie', identifiantSubvention.toString());
  parameterSubvention.append('all', 'true');

  const parameterProjet = new URLSearchParams();
  parameterProjet.append('identifiantFichierLie', identifiantProjet.toString());
  parameterProjet.append('nomFichierLie', Fichiers.PROJET);
  parameterProjet.append('all', 'true');

  return Promise.all([
    request.get<unknown, TemplateRequestGet<PieceJointe>>(
      `/api/finances/comptabilite/piecesjointescomptabilite/?${parameterSubvention.toString()}`,
    ),
    request.get<unknown, TemplateRequestGet<PieceJointe>>(
      `/api/finances/comptabilite/piecesjointescomptabilite/?${parameterProjet.toString()}`,
    ),
  ]);
};

export default getNatureFromGabarits;
