import React, { useState } from 'react';
import {
  Form,
  Accordion,
  Header,
  Segment,
} from '@jvs-group/jvs-mairistem-composants';
import EtiquettesInput from '@/src/components/Common/EtiquettesInput';
import '@/src/components/Planning/planning.less';
import PlanningSearchDropdown from '@/src/components/Planning/PlanningSearchDropdown';

const PlanningSearchPanel = ({
  onSearch,
  identifiantEntite,
  onChange,
  selectedEtiquettes,
  yearOptions,
  mandatOptions,
  onYearChange,
  isMandat,
  setIsMandat,
}) => {
  const [active, setActive] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedYear, setSelectedYear] = useState<number | null>(new Date().getFullYear());

  const handleSearch = () => {
    onSearch(searchQuery);
  };

  const handleYearChange = (event, data) => {
    setSelectedYear(data.value);
    if (onYearChange) {
      onYearChange(data.value);
    }
    setIsMandat(false);
  };

  const handleMandatChange = (event, data) => {
    const [start] = data.value.split(' - ');
    const startYearValue = parseInt(start, 10);
    if (onYearChange) {
      onYearChange(startYearValue);
    }
    setSelectedYear(startYearValue);
    setIsMandat(true);
  };

  const handleToggleAccordion = () => setActive((old) => !old);

  return (
    <Accordion className="fiche-panel-header planningSearchAccordion segment">
      <Accordion.Title
        active={active}
        content={(
          <div className="accordionTitleContainer">
            <Header size="small">CRITÈRES DE SÉLECTION</Header>
          </div>
        )}
        onClick={handleToggleAccordion}
      />
      <Accordion.Content active={active} as={Segment} basic>
        <Form onSubmit={handleSearch}>
          <Form.Input
            action={{ icon: 'search', onClick: handleSearch }}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Filtrer les projets en saisissant un libellé ou une référence interne..."
            value={searchQuery}
          />
        </Form>
        <Form>
          <Form.Group widths="equal">
            <Form.Field
              control={PlanningSearchDropdown}
                // @ts-expect-error isMandat
              isMandat={isMandat}
              mandatOptions={mandatOptions}
              onMandatChange={handleMandatChange}
              label="‎"
              onYearChange={handleYearChange}
              selectedYear={selectedYear}
              yearOptions={yearOptions}
            />

            <Form.Field
                // @ts-expect-error allowAdditions
              allowAdditions={false}
              control={EtiquettesInput}
              fluid
              identifiantEntite={identifiantEntite}
              label="Etiquettes associés"
              onChange={onChange}
              selectedEtiquettes={selectedEtiquettes}
            />
          </Form.Group>
        </Form>
      </Accordion.Content>
    </Accordion>
  );
};

export default PlanningSearchPanel;
