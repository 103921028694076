import React from 'react';
import { Liste } from '@jvs-group/jvs-mairistem-liste';
import { Header, Icon, Popup } from '@jvs-group/jvs-mairistem-composants';
import { NavLink } from 'react-router-dom';
import ProjetListeTable from '@/src/components/ProjetListe/ProjetListeTable';
import { Projet } from '@/src/interfaces/projet';

interface ProjetListeProps {
  projets: Array<Projet>,
  count: number,
  perPage: number,
  loading: boolean,
  onLoad: any,
  onSearch: any,
  onSort: any,
  onSelected: any,
  onExplored: any,
  onExpanded: any,
  state: any,
  mode: any,
}

const ProjetListe = (props : ProjetListeProps) => {
  const {
    projets,
    count,
    perPage,
    loading,
    onLoad,
    onSort,
    onSelected,
    mode,
    ...handlers
  } = props;

  return (
    <>
      <Liste
        title="Projets"
        mode={mode}
        searchable
        // @ts-expect-error
        persistState={false}
        {...handlers}
      >
        <Liste.Header>
          {
                    mode !== 'explorer'
                    && (
                    <Header size="small">
                      {count}
                      {' '}
                      {(count > 1) ? 'projets' : 'projet' }
                    </Header>
                    )
                }
        </Liste.Header>
        <Liste.Content>
          <ProjetListeTable
            items={projets}
            loading={loading}
            count={count}
            perPage={perPage}
            onLoad={onLoad}
            onSort={onSort}
            onSelect={onSelected}
          />
        </Liste.Content>

      </Liste>

      <Popup
        content="Nouvelle fiche"
        trigger={(
          <NavLink
            to="/projets/_"
            className="buttonAdd"
          >
            <Icon
              name="plus"
              circular
              inverted
              link
              size="large"
              color="blue"
            />
          </NavLink>
      )}
      />

    </>
  );
};

export default ProjetListe;
