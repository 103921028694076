import React from 'react';

interface CircularProgressBarProps {
  width?: number
  value: number
  strokeWidth?: number
  innerStrokeWidth?: number
}

const CircularProgressBar = ({
  width,
  value,
  strokeWidth,
  innerStrokeWidth,
}: CircularProgressBarProps) => {
  const [parentWidth, setParentWidth] = React.useState(0);
  const parentRef = React.useRef(null);
  const size = width || parentWidth;

  const radius = size / 2;
  const normalizedRadius = radius - strokeWidth * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = (circumference - ((100 - value) / 100) * circumference) * -1;

  React.useLayoutEffect(() => {
    if (parentRef.current) {
      setParentWidth(parentRef.current.clientWidth);
    }
  }, [setParentWidth]);

  return (
    <div ref={parentRef} style={{ width: '100%', height: '100%' }}>
      <svg id="svg" width={size} height={size}>
        <circle
          r={normalizedRadius}
          cx={radius}
          cy={radius}
          style={{ stroke: '#109fdb', strokeWidth }}
          strokeDasharray={`${circumference} ${circumference}`}
          fill="transparent"
        />
        <circle
          r={normalizedRadius}
          cx={radius}
          cy={radius}
          strokeDasharray={`${circumference} ${circumference}`}
          style={{ stroke: '#EBF2FA', strokeWidth: innerStrokeWidth, strokeDashoffset }}
          fill="transparent"
          className="circular progressbar value"
        />
        <text
          x={radius}
          y={radius}
          fontSize="2rem"
          textAnchor="middle"
          dominantBaseline="middle"
          fill="#109fdb"
        >
          {value}
          %
        </text>
      </svg>
    </div>
  );
};

CircularProgressBar.defaultProps = {
  width: null,
  strokeWidth: 15,
  innerStrokeWidth: 6,
};

export default CircularProgressBar;
