// @ts-nocheck

import React from 'react';
import { ListeTable } from '@jvs-group/jvs-mairistem-liste';
import { Subvention } from '@/src/interfaces/subvention';

interface SubventionListeTableProps {
  items: Array<Subvention>,
  count: number,
  perPage: number,
  loading: boolean,
  onLoad: any,
  onSelect: any,
  onSort: any,
}

const SubventionListeTable = (props : SubventionListeTableProps) => {
  const {
    items,
    count,
    perPage,
    loading,
    onLoad,
    onSort,
    onSelect,
  } = props;

  return (
    <ListeTable
      items={items}
      loading={loading}
      count={count}
      perPage={perPage}
      onLoad={onLoad}
      onSort={onSort}
      onSelect={onSelect}
    >
      <ListeTable.Header>
        <ListeTable.Column
          name="libelle"
          width={6}
        >
          Libellé
        </ListeTable.Column>
      </ListeTable.Header>
      {(subvention) => (
        <ListeTable.Row>
          <ListeTable.Cell name="libelle">{subvention?.libelle}</ListeTable.Cell>
        </ListeTable.Row>
      )}
    </ListeTable>
  );
};

export default SubventionListeTable;
