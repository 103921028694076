import React from 'react';

import {
  Input,
  Currency,
  Grid,
  Button,
  Toggle,
  Form,
} from '@jvs-group/jvs-mairistem-composants';

import { ErrorField }
  from '@jvs-group/jvs-mairistem-fiche/lib/ts/types';
import SubventionFinanceur
  from '@/src/components/SubventionFichePanel/Generalite/SubventionFinanceur/SubventionFinanceur';
import SubventionProjet from '@/src/components/SubventionFichePanel/Generalite/SubventionProjet/SubventionProjet';

import { isError } from '@/src/utils/tools';
import { Etiquette } from '@/src/interfaces/etiquette';
import EtiquettesInput from '@/src/components/Common/EtiquettesInput';
import { Projet } from '@/src/interfaces/projet';
import { Composant } from '@/src/interfaces/composant';
import { getAssietteEligible, getMontantDepensesEligibles } from '@/src/utils/subvention';

interface SubventionInformationProps {
  identifiant: number,
  financeur: any,
  libelle: string,
  identifiantEntite: number,
  projet: Projet,
  etiquettes: Array<Etiquette>,
  etiquettesProjet: Array<Etiquette>,
  isCloture: boolean,
  onChange: any,
  errors: Array<ErrorField>,
  realisationHT: boolean,
  plafondEligible: number,
  tauxAide: number,
  montantEnvisage: number,
  assietteEligible: number,
  composants: Partial<Composant>[],
}

const SubventionInformation = (
  {
    identifiant = null,
    financeur = null,
    libelle = '',
    identifiantEntite = null,
    projet = null,
    assietteEligible = 0,
    etiquettes = [],
    etiquettesProjet = [],
    isCloture = false,
    onChange = null,
    realisationHT = true,
    plafondEligible = null,
    tauxAide = null,
    montantEnvisage = null,
    composants = [],
    errors = [],
  } : SubventionInformationProps,
) => {
  const handleChange = React.useCallback(({ target }) => {
    const {
      name,
      value,
    } = target;
    if (onChange) {
      onChange({
        identifiant,
        [name]: value,
      });
    }
  }, [identifiant, onChange]);

  const handleEtiquetteChange = React.useCallback((changes) => {
    if (onChange) {
      onChange({
        identifiant,
        ...changes,
      });
    }
  }, [identifiant, onChange]);
  const subventionProjet = React.useMemo(() => (
    <SubventionProjet
      identifiantEntite={identifiantEntite}
      projet={projet}
      errors={errors}
      onChange={onChange}
      selectedComposants={composants}
      identifiant={identifiant}
      realisationHT={realisationHT}
      plafondEligible={plafondEligible}
      tauxAide={tauxAide}
    />
  ), [
    identifiantEntite,
    projet,
    errors,
    composants,
    identifiant,
    plafondEligible,
    realisationHT,
    onChange,
    tauxAide,
  ]);

  const handleChangeEtat = (index) => {
    onChange({
      identifiant,
      cloture: index !== 0,
    });
  };

  const handleRealisationHT = (index) => {
    onChange({
      identifiant,
      realisationHT: index === 0,
    });
  };

  const handleChangeTauxAide = (e, value) => {
    if (onChange) {
      onChange({
        identifiant,
        tauxAide: value,
        montantEnvisage: (assietteEligible * value) / 100,
      });
    }
  };

  const handleChangeMontantAssietteEligible = (e, value) => {
    onChange?.({
      identifiant,
      assietteEligible: value,
      montantEnvisage: (value * tauxAide) / 100,
    });
  };

  const handleChangeMontantEnvisage = (e, value) => {
    onChange?.({
      identifiant,
      tauxAide: (value * 100) / assietteEligible,
      montantEnvisage: value,
    });
  };

  const handleChangePlafond = (e, value) => {
    const assietteEligible = getAssietteEligible(
      value,
      composants as Composant[],
      realisationHT,
    );

    onChange?.({
      identifiant,
      plafondEligible: value,
      montantEnvisage: (assietteEligible * tauxAide) / 100,
      assietteEligible,
    });
  };

  const montantDepensesEligibles = getMontantDepensesEligibles(composants as Composant[], realisationHT);

  return (
    <Form>
      <div className="etatSubvention">
        <Toggle onChange={handleChangeEtat}>
          <Button active={!isCloture} data-testid="etatActifSubvention">Actif</Button>
          <Button active={isCloture} data-testid="etatClotureSubvention">Clôturé</Button>
        </Toggle>
      </div>
      <div className="financeur">
        <SubventionFinanceur financeur={financeur} onChange={handleChange} />
      </div>
      {subventionProjet}
      <Grid stackable padded={false} stretched>
        <Grid.Row>
          <Grid.Column width={16} className="no-padding">
            <Input
              key="libelle"
              name="libelle"
              id="subventionspercues-libelle"
              value={libelle}
              label="Libellé Subvention"
              placeholder="Libellé Subvention"
              onChange={handleChange}
              fluid
              required
              list="libelleSubvention"
              error={isError(errors, 'subventionspercues-libelle')}
              data-testid="libelleSubvention"
            />
          </Grid.Column>

          <datalist id="libelleSubvention">
            <option>
              Aide
              {' '}
              {financeur?.nom}
              {' '}
              {financeur?.prenom}
              {' '}
              pour
              {' '}
              {projet?.libelle}
            </option>
          </datalist>

        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16} className="no-padding">
            <Grid stackable>
              <Grid.Row>
                <Grid.Column width={3} className="no-padding">
                  <Toggle onChange={handleRealisationHT} label="Réalisation exprimée en">
                    <Button active={realisationHT} data-testid="realisationHtSubvention">HT</Button>
                    <Button active={!realisationHT} data-testid="realisationTtcSubvention">TTC</Button>
                  </Toggle>
                </Grid.Column>

                <Grid.Column width={7}>
                  <Currency
                    key="montantDepensesEligibles"
                    name="montantDepensesEligibles"
                    label="Montant des dépenses éligibles"
                    value={montantDepensesEligibles}
                    fluid
                    disabled
                    data-testid="montantDepensesEligiblesSubvention"
                  />
                </Grid.Column>

                <Grid.Column width={6} className="no-padding">
                  <Currency
                    key="plafondEligible"
                    name="plafondEligible"
                    label="Plafond éligible subventionnable"
                    value={plafondEligible}
                    onBlur={handleChangePlafond}
                    fluid
                    data-testid="plafondEligibleSubvention"
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid stackable>
              <Grid.Row>
                <Grid.Column width={6} className="no-padding">
                  <Currency
                    key="assietteEligible"
                    name="assietteEligible"
                    label="Assiette éligible"
                    value={assietteEligible}
                    fluid
                    onChange={handleChangeMontantAssietteEligible}
                    data-testid="assietteEligibleSubvention"
                  />
                </Grid.Column>

                <Grid.Column width={4}>
                  <Currency
                    key="tauxAide"
                    name="tauxAide"
                    label="Taux"
                    value={tauxAide}
                    onBlur={handleChangeTauxAide}
                    fluid
                    percent
                    data-testid="tauxAideSubvention"
                  />
                </Grid.Column>

                <Grid.Column width={6} className="no-padding">
                  <Currency
                    key="montantEnvisage"
                    name="montantEnvisage"
                    label="Montant envisagé"
                    value={montantEnvisage}
                    onBlur={handleChangeMontantEnvisage}
                    fluid
                    data-testid="montantEnvisageSubvention"
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16} className="no-padding">
            <EtiquettesInput
              identifiantEntite={identifiantEntite}
              selectedEtiquettes={etiquettes}
              legacyEtiquettes={etiquettesProjet}
              onChange={handleEtiquetteChange}
              allowAdditions
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

export default SubventionInformation;
