import React from 'react';

import {
  Placeholder,
  Popup,
  Button,
  Menu,
} from '@jvs-group/jvs-mairistem-composants';

import { useSelector, request } from '@jvs-group/jvs-mairistem-store';
import Compressor from 'compressorjs';
import { getPjDownloadUrl } from '@/src/config/url';
import authentificationService from '@/src/services/authentification';

interface AvatarProps {
  identifiant: number,
  identifiantDocInfo: number,
  handleChange?: any,
}

const Avatar = (
  {
    identifiant = null,
    identifiantDocInfo = null,
    handleChange = null,
  } : AvatarProps,
) => {
  const DEFAULT_AVATAR = '/assets/images/empty-image.png';

  const [avatar, setAvatar] = React.useState(DEFAULT_AVATAR);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const fileUpload = React.useRef(null);

  // @ts-expect-error
  const token = useSelector(authentificationService, (data) => data?.token);

  React.useEffect(() => {
    setAvatar(DEFAULT_AVATAR);
    if (identifiantDocInfo != null) {
      setIsLoading(true);
      request.get<unknown, Blob>(getPjDownloadUrl(identifiantDocInfo), {
        responseType: 'blob',
      }).then((response) => setAvatar(URL.createObjectURL(response)))
        .finally(() => setIsLoading(false));
    }
  }, [identifiantDocInfo, token]);

  const getImageComponent = () => {
    if (isLoading) {
      return (
        <Placeholder className="placeloaderAvatarPopup">
          <Placeholder.Image />
        </Placeholder>
      );
    }

    return (
      <div
        className="avatar"
        style={{ backgroundImage: `url(${avatar})` }}
      />
    );
  };

  const handleClickTrigger = (event) => {
    event.stopPropagation();
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  const handleOpenMenu = () => {
    setIsMenuOpen(true);
  };

  const handleUpdateAvatar = () => {
    fileUpload.current.click();
  };

  const toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const handleRemoveAvatar = () => {
    URL.revokeObjectURL(avatar);
    handleChange({ identifiant, identifiantDocInfo: null, avatar: null });
    setIsMenuOpen(false);
    setAvatar(DEFAULT_AVATAR);
  };

  const handleCompressedUpload = (e) => {
    const image = e.target.files[0];
    setIsLoading(true);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const compressor = new Compressor(image, {
      quality: 0.6,
      success: (compressedResult) => {
        setIsLoading(false);
        if (avatar !== DEFAULT_AVATAR) {
          URL.revokeObjectURL(avatar);
        }

        setAvatar(URL.createObjectURL(compressedResult));

        toBase64(compressedResult).then((base64Avatar) => {
          handleChange({ identifiant, avatar: base64Avatar, avatarFileName: image.name });
        });
      },
      error: () => {
        setAvatar(DEFAULT_AVATAR);
        setIsLoading(false);
      },
    });
  };

  const getMenuPopup = () => (
    <Popup
      trigger={<Button icon="bars" className="avatar-menu" onClick={handleClickTrigger} />}
      as={Menu.Item}
      content={(
        <Menu fluid vertical secondary>
          <Menu.Item name="modifier_avatar" onClick={handleUpdateAvatar}>Modifier</Menu.Item>
          <Menu.Item
            name="remove_supprimer"
            onClick={handleRemoveAvatar}
            disabled={avatar === DEFAULT_AVATAR}
          >
            Supprimer
          </Menu.Item>
        </Menu>
        )}
      on="click"
      open={isMenuOpen}
      onClose={handleCloseMenu}
      onOpen={handleOpenMenu}
      position="bottom right"
    />
  );

  return (
    <div className="full height">
      {getImageComponent()}
      { handleChange && getMenuPopup()}
      <input
        accept="image/*,capture=camera"
        type="file"
        ref={fileUpload}
        className="hidden"
        onChange={(event) => handleCompressedUpload(event)}
      />
    </div>
  );
};

export default Avatar;
