// @ts-nocheck

import { ListeTable, ListeTableContainer } from '@jvs-group/jvs-mairistem-liste';
import { request } from '@jvs-group/jvs-mairistem-store';
import _ from 'lodash';
import React from 'react';
import EtiquetteRow from '@/src/components/Parametres/EtiquetteListe/EtiquetteRow';

const EtiquetteListe = () => {
  const [etiquettes, setEtiquettes] = React.useState({
    data: [],
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [errors, setErrors] = React.useState([]);

  const loadEtiquettes = () => {
    setIsLoading(true);
    const url = '/api/finances/subventionsPercues/etiquettes';

    request.get(url)
    // @ts-expect-error
      .then((data) => {
        setEtiquettes(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    loadEtiquettes();
  }, []);

  const handleOnLoad = () => {
    loadEtiquettes();
  };

  const checkEtiquette = (item) => {
    const errs = [];
    if (_.isNil(item?.libelle)) {
      errs.push({
        itemId: item?.identifiant,
        field: 'libelle',
        message: 'Le libelle est obligatoire',
      });
    }

    return errs;
  };

  const createEtiquette = async (item) => {
    const createdItem = await request.post('/api/finances/subventionsPercues/etiquettes', _.omit(item, ['identifiant']));
    setEtiquettes((state) => ({
      // @ts-expect-error
      data: [...state.data.filter((et) => et.identifiant !== createdItem.identifiant), createdItem],
    }));

    return createdItem;
  };

  const updateEtiquette = (item) => {
    request.put(`/api/finances/subventionsPercues/etiquettes/${item?.identifiant}`, item)
      // @ts-expect-error
      .then((updatedItem) => {
        setEtiquettes((state) => ({
          data: [...state.data.filter((et) => et.identifiant !== item?.identifiant), updatedItem],
        }));
      });

    return true;
  };

  const handleDelete = async (item) => {
    const response = await request.delete(`/api/finances/subventionsPercues/etiquettes/${item?.identifiant}`, item);

    // @ts-expect-error
    if (response.status === 204) {
      setEtiquettes((state) => ({
        data: [...state.data.filter((et) => et.identifiant !== item?.identifiant)],
      }));

      return true;
    }

    return false;
  };

  const handleValidate = (item, status) => {
    const errs = checkEtiquette(item);
    if (errs?.length > 0) {
      setErrors(errs);
      return false;
    }

    setErrors([]);

    switch (status) {
      case 'creating':
        return createEtiquette(item);
      case 'updating':
        updateEtiquette(item);
        break;
      default:
    }

    return true;
  };

  const handleCancelClick = () => { setErrors([]); };

  return (
    <ListeTableContainer
      items={etiquettes?.data}
      onValidateClick={handleValidate}
      onDeleteClick={handleDelete}
      allowModifications
      allowDeletions
      allowAdditions
      onCancelClick={handleCancelClick}
      errors={errors}
    >
      <ListeTable
        count={etiquettes?.data?.length || 0}
        loading={isLoading}
        onLoad={handleOnLoad}
        perPage={etiquettes?.data?.length || 0}
      >
        <ListeTable.Header>
          <ListeTable.Column name="libelle" width={16}>Libellé</ListeTable.Column>
        </ListeTable.Header>
        {(etiquette, invalidate, rowProps, utilities) => (
          <EtiquetteRow
            key={etiquette?.identifiant}
            rowProps={rowProps}
            utilities={utilities}
            etiquette={etiquette}
          />
        )}
      </ListeTable>
    </ListeTableContainer>
  );
};

export default EtiquetteListe;
