import * as React from 'react';

import {
  render,
} from '@jvs-group/jvs-mairistem-module';

import '@/src/index.less';
import AppSubvention from '@/src/AppSubvention';
import 'react-toastify/dist/ReactToastify.css';
import 'react-semantic-toasts/styles/react-semantic-alert.css';

render(
  <AppSubvention />,
);
