import { createService } from '@jvs-group/jvs-mairistem-store';

export const ACTION_SET_TOKEN = 'SET_TOKEN';

const authentificationService = createService('authentification', (authentification) => authentification
  .action(ACTION_SET_TOKEN, (token) => ({
    data: {
      token,
    },
  })));
export default authentificationService;
