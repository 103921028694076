import { configureStore } from '@jvs-group/jvs-mairistem-store';

const store = configureStore(
  {
    navigation: {
      data: {
        domain: {
          color: 'purple',
        },
      },
    },
  },
  'subventions',
);

export default store;
