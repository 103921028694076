import React from 'react';
import {
  ListeRiche,
  OptionsSelectionAvancees,
  typeString,
  typeBoolean,
} from '@jvs-group/jvs-mairistem-liste';
import { request } from '@jvs-group/jvs-mairistem-store';
import {
  Dropdown,
  Header,
  Icon,
  Menu,
  Segment,
} from '@jvs-group/jvs-mairistem-composants';
import _ from 'lodash';
import SubventionListeRicheItem from '@/src/components/Dashboard/SubventionListeRiche/SubventionListeRicheItem';

const SORT_FINANCEUR = 'financeur';
const SORT_PROJET = 'projet';

const sortOptions = [
  { key: SORT_PROJET, value: SORT_PROJET, text: 'Projet' },
  { key: SORT_FINANCEUR, value: SORT_FINANCEUR, text: 'Financeur' },
];

const SubventionListeRiche = () => {
  const [sort, setSort] = React.useState(SORT_PROJET);
  const [data, setData] = React.useState(null);
  const [results, setResults] = React.useState(null);
  const [loading, setLoading] = React.useState(null);
  const [filtersData, setFiltersData] = React.useState([]);

  const filterItems = React.useMemo(() => [
    {
      id: 'sub_libelle',
      libelle: 'Subvention',
      type: typeString,
    },
    {
      id: 'sub_cloture',
      libelle: 'Etat',
      type: typeBoolean,
      value: 1,
      choices: [
        {
          key: 'tous',
          text: 'Tous',
          value: null,
        },
        {
          key: 'actif',
          text: 'Actif',
          value: false,
        },
        {
          key: 'cloture',
          text: 'Cloturé',
          value: true,
        },
      ],
    },
  ], []);

  const getResults = React.useCallback((subventions, currentSort) => {
    if (subventions == null) {
      return [
        {
          results: [],
        },
      ];
    }

    let resultsData = [];
    _.forEach(subventions?.data, (subvention) => {
      let itemSort = null;

      switch (currentSort) {
        case SORT_PROJET:
          itemSort = {
            id: subvention?.projet?.identifiant,
            title: subvention?.projet?.libelle,
            sort: new Date(subvention?.projet?.dateFin) ?? null,
          };
          break;
        case SORT_FINANCEUR: default:
          itemSort = {
            id: subvention?.financeur?.identifiant,
            title: subvention?.financeur?.nom,
            sort: subvention?.financeur?.nom,
          };
          break;
      }

      const regroupement = resultsData.find((element) => element.id === itemSort.id);

      if (regroupement) {
        regroupement.results.push(subvention);
      } else {
        resultsData.push({
          itemControl: SubventionListeRicheItem,
          results: [subvention],
          currentPage: 1,
          lastPage: 1,
          title: itemSort.title,
          id: itemSort.id,
          sort: itemSort.sort,
        });
      }
    });

    resultsData = _.sortBy(resultsData, (r) => r.sort);
    return resultsData;
  }, []);

  const handleGetData = React.useCallback(() => {
    setLoading(true);
    const urlFilters = {
      mode: 'EN_COURS',
      all: true,
      filters: null,
    };

    if (filtersData) {
      urlFilters.filters = JSON.stringify(filtersData);
    }

    // @ts-expect-error
    request.get(`/api/finances/subventionsPercues?${new URLSearchParams(urlFilters)}`)
      .then((subventions) => {
        setData(subventions);
        setResults(getResults(subventions, sort));
        setLoading(false);
      });
  }, [getResults, sort, filtersData]);

  React.useEffect(() => {
    handleGetData();
  }, [handleGetData]);

  const handleSortChange = React.useCallback((e, { value }) => {
    setSort(value);
    setResults(getResults(data, sort));
  }, [
    data,
    sort,
    getResults,
  ]);

  const handleFilterChange = React.useCallback((e, filters) => {
    setFiltersData(filters.map((filter) => _.pick(filter, [
      'id',
      'condition',
      'value',
      'type',
      'sort',
    ])));
  }, []);

  return (
    <>
      <ListeRiche
        loading={loading}
        defaultActive
        results={results}
        sortComponent={(
          <Menu.Item
            inline
          >
            <Dropdown
              options={sortOptions}
              value={sort}
              onChange={handleSortChange}
            />
          </Menu.Item>
                )}
        filtres={[
          (
            <OptionsSelectionAvancees
              // @ts-expect-error
              items={filterItems}
              key="filters"
              trigger={(
                <Menu compact secondary text>
                  <Menu.Item link data-testid="filtreAvancee">
                    Filtre avancé &nbsp;
                    <Icon name="edit" />
                  </Menu.Item>
                </Menu>
                                )}
              onSave={handleFilterChange}
            />
          ),
        ]}
      />

      {_.isEmpty(results) && !loading && (
        <Segment placeholder>
          <Header icon>
            <Icon name="search" />
            Nous n&apos;avons trouvé aucun résultat correspondant aux critères de votre recherche.
          </Header>
        </Segment>
      )}
    </>
  );
};

export default SubventionListeRiche;
