import React from 'react';
import { Form, TextArea } from '@jvs-group/jvs-mairistem-composants';

interface CommentairesProps {
  identifiant: number,
  commentaire: string,
  infosPubliques: string,
  onChange: any,
}

const Commentaires = (
  {
    identifiant,
    commentaire,
    infosPubliques,
    onChange,
  } : CommentairesProps,
) => {
  const handleChange = ({ target: { name, value } }) => {
    if (onChange) {
      onChange({ identifiant, [name]: value });
    }
  };

  return (
    <Form>
      <Form.Group widths="equal">
        <Form.Field
          name="commentaire"
          // @ts-expect-error
          control={TextArea}
          label="Informations internes"
          value={commentaire}
          onChange={handleChange}
          data-testid="commentaireSubvention"
        />
        <Form.Field
          name="infosPubliques"
          // @ts-expect-error
          control={TextArea}
          label="Informations publiques"
          value={infosPubliques}
          onChange={handleChange}
          data-testid="infosPubliquesSubvention"
        />
      </Form.Group>
    </Form>
  );
};

export default Commentaires;
