import React from 'react';

import { FicheBarContainer } from '@jvs-group/jvs-mairistem-fiche';
import SubventionFicheBarConsumer,
{ handleCreatingFicheSubvention } from '@/src/components/SubventionFiche/SubventionFicheBarConsumer';

import subventionService from '@/src/services/subventionService';

const SubventionFicheBar = ({
  ...rest
}) => (
  <FicheBarContainer
    type="subvention"
    {...rest}
    hasHistory={false}
    service={subventionService}
    onCreating={handleCreatingFicheSubvention}
  >
    {/* @ts-expect-error */}
    <SubventionFicheBarConsumer />
  </FicheBarContainer>
);

export default SubventionFicheBar;
