import React from 'react';

import { formatMontant } from '@jvs-group/jvs-mairistem-finances-utils';
import { Checkbox, Table } from '@jvs-group/jvs-mairistem-composants';
import _ from 'lodash';
import Progress from '@/src/components/Common/EcrituresComptables/Progress';
import { getMontantPieceConditions, getMontantRealiseConditions } from '@/src/utils/conditionVersement';
import { getMontantMouvementProjet, getMontantRealiseProjet, getProgressProjet } from '@/src/utils/projet';
import { Projet } from '@/src/interfaces/projet';
import { ConditionVersement } from '@/src/interfaces/conditionVersement';
import { Composant } from '@/src/interfaces/composant';

interface EcrituresComptablesProps {
  projet: Projet,
  conditionsVersements: Array<ConditionVersement>,
  montantSubvention: number,
  realisationHT: boolean,
  composants ?: Composant[],
  montantProjet: number,
}

const EcrituresComptables = (
  {
    projet = null,
    realisationHT = null,
    conditionsVersements = [],
    montantSubvention = 0,
    composants = null,
    montantProjet = null,
  } : EcrituresComptablesProps,
) => {
  const [visibility, setVisibility] = React.useState({ subvention: true, projet: true });

  const montantRealiseConditions = React.useMemo(
    () => getMontantRealiseConditions(conditionsVersements, realisationHT),
    [conditionsVersements, realisationHT],
  );

  const getProgressSubvention = () => ({
    total: montantSubvention,
    progress: montantRealiseConditions,
    steps: [100],
  });

  const getMontantMouvementProjetCallback = React.useCallback(
    getMontantMouvementProjet,
    [],
  );

  const getMontantPieceConditionsCallback = React.useCallback(
    getMontantPieceConditions,
    [],
  );

  const montantRealiseProjet = React.useMemo(
    () => getMontantRealiseProjet(
      projet?.identifiant,
      projet?.mouvements,
      projet?.realiseAnterieur,
      realisationHT,
      composants,
    ),
    [
      projet?.identifiant,
      projet?.mouvements,
      projet?.realiseAnterieur,
      realisationHT,
      composants,
    ],
  );

  const toDateString = (date) => new Date(date).toLocaleDateString(
    [],
    { year: 'numeric', month: '2-digit', day: '2-digit' },
  );

  const showPiece = (mouvement, versement = null) => {
    const isLinkedToComposant = composants === null || !_.isNil(mouvement?.lignes?.find(
      (l) => composants.find((c) => c.identifiant === l.identifiantComposant),
    ));

    if (mouvement.codeBHM !== 'H' || (_.isNil(versement) && !isLinkedToComposant)) return null;

    const montants = _.isNil(versement)
      ? getMontantMouvementProjetCallback(mouvement, projet?.identifiant, composants)
      : getMontantPieceConditionsCallback(mouvement, versement);

    return (
      <Table.Row key={mouvement?.identifiant}>
        <Table.Cell>{mouvement.date && toDateString(mouvement.date)}</Table.Cell>
        <Table.Cell>
          {versement === null ? 'Acquisition' : versement.libelle}
          {' '}
        </Table.Cell>
        <Table.Cell>
          <div>
            {mouvement?.exercice?.entite?.raisonSociale}
            -
            Exercice
            {' '}
            {mouvement?.exercice?.anneeExercice}
          </div>
          <div>
            Bd
            {' '}
            {mouvement?.bordereau?.numero}
            {' '}
            -
            {' '}
            {mouvement.sens === 'D' ? 'Mandat' : 'Titre'}
            {' '}
            {mouvement.numero}
          </div>
        </Table.Cell>
        <Table.Cell>{mouvement.informationTiers}</Table.Cell>
        <Table.Cell>{mouvement.informationImputation}</Table.Cell>
        <Table.Cell>{mouvement.objetPiece}</Table.Cell>
        <Table.Cell textAlign="right">{formatMontant(montants?.ht)}</Table.Cell>
        <Table.Cell textAlign="right">{formatMontant(montants?.ttc)}</Table.Cell>
      </Table.Row>
    );
  };

  const showAllPiece = () => {
    const mouvements = [];
    if (visibility.subvention) {
      _.forEach(conditionsVersements, (versement) => {
        _.forEach(versement?.mouvements, (mouvement) => {
          const p = showPiece(mouvement, versement);
          if (!_.isEmpty(p)) {
            mouvements.push(p);
          }
        });
      });
    }

    if (visibility.projet && projet?.mouvements) {
      _.forEach(projet?.mouvements, (mouvement) => {
        const p = showPiece(mouvement);
        if (!_.isEmpty(p)) {
          mouvements.push(p);
        }
      });
    }

    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {mouvements.length === 0
          ? (
            <Table.Row key="empty">
              <Table.Cell textAlign="center" colspan="8">Aucune donnée à afficher</Table.Cell>
            </Table.Row>
          ) : (
            mouvements.map((mouvement) => mouvement))}
      </>
    );
  };

  const handleOnVisibilityChange = (e, { name, checked }) => {
    setVisibility((state) => ({ ...state, [name]: checked }));
  };

  const progressSubvention = getProgressSubvention();
  const progressProjet = getProgressProjet(
    conditionsVersements,
    montantRealiseProjet,
    montantProjet,
  );

  const labelProjetCheckbox = composants === null ? 'Projet' : 'Composant(s) du projet';
  return (
    <>
      <Checkbox
        name="subvention"
        className="visibility"
        label={`Subvention (Financé: ${formatMontant(progressSubvention?.total)})`}
        checked={visibility.subvention}
        onChange={handleOnVisibilityChange}
      />

      <Progress progress={progressSubvention} color="teal" />

      <Checkbox
        name="projet"
        className="visibility"
        label={`${labelProjetCheckbox} (${formatMontant(progressProjet?.total ?? 0)})`}
        checked={visibility.projet}
        onChange={handleOnVisibilityChange}
      />

      <Progress progress={progressProjet} color="red" />

      <Table celled striped id="tableEcritures">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Référence</Table.HeaderCell>
            <Table.HeaderCell>Tiers</Table.HeaderCell>
            <Table.HeaderCell>Imputation</Table.HeaderCell>
            <Table.HeaderCell>Désignation</Table.HeaderCell>
            <Table.HeaderCell>Montant HT</Table.HeaderCell>
            <Table.HeaderCell>Montant TTC</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {showAllPiece()}
        </Table.Body>
      </Table>
    </>
  );
};

EcrituresComptables.defaultProps = {
  composants: null,
};

export default EcrituresComptables;
