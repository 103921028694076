import React from 'react';
import { Liste } from '@jvs-group/jvs-mairistem-liste';
import { Header, Icon, Popup } from '@jvs-group/jvs-mairistem-composants';
import { NavLink } from 'react-router-dom';
import SubventionListeTable from '@/src/components/SubventionListe/SubventionListeTable';
import { Subvention } from '@/src/interfaces/subvention';

interface SubventionListeProps {
  subventions?: Array<Subvention>,
  count?: number,
  perPage?: number,
  loading?: boolean,
  onLoad?: any,
  onSearch?: any,
  onSort?: any,
  onSelected?: any,
  onExplored?: any,
  onExpanded?: any,
  state?: any,
  mode?: 'expander' | 'explorer',
}

const SubventionListe = (props : SubventionListeProps) => {
  const {
    subventions,
    count,
    perPage,
    loading,
    onLoad,
    onSort,
    onSelected,
    mode,
    ...handlers
  } = props;

  return (
    <>
      <Liste
        title="Subventions"
        mode={mode}
        searchable
        {...handlers}
      >
        <Liste.Header>
          {
              mode !== 'explorer'
              && (
              <Header size="small">
                {count}
                {' '}
                {(count > 1) ? 'subventions' : 'subvention' }
              </Header>
              )
          }
        </Liste.Header>
        <Liste.Content>
          <SubventionListeTable
            items={subventions}
            loading={loading}
            count={count}
            perPage={perPage}
            onLoad={onLoad}
            onSort={onSort}
            onSelect={onSelected}
          />
        </Liste.Content>

      </Liste>

      <Popup
        content="Nouvelle fiche"
        trigger={(
          <NavLink to="/subventionsPercues/_" className="buttonAdd">
            <Icon
              name="plus"
              circular
              inverted
              link
              size="large"
              color="blue"
            />
          </NavLink>
      )}
      />

    </>
  );
};

export default SubventionListe;
