import _ from 'lodash';

export const toDateString = (date) => {
  if (!date) return null;

  return new Date(date).toLocaleDateString([], { year: 'numeric', month: '2-digit', day: '2-digit' });
};
export default toDateString;

export const isError = (errors, id) => _.some(errors, (e) => e.id === id);

export const getTooltipError = (errors, id) => {
  const error = _.find(errors, (e) => e.id === id);

  if (!_.isNil(error)) {
    return {
      type: 'error',
      message: error.message,
    };
  }

  return null;
};
