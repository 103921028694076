import React from 'react';
import { Dropdown, type DropdownItemProps } from '@jvs-group/jvs-mairistem-composants';

interface PlanningSearchDropdownProps {
    isMandat: boolean;
    mandatOptions: any;
    onMandatChange: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, data: DropdownItemProps) => void;
    onYearChange: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, data: DropdownItemProps) => void;
    selectedYear: number;
    yearOptions: any;
}

const PlanningSearchDropdown = ({
  isMandat,
  mandatOptions,
  onMandatChange,
  onYearChange,
  selectedYear,
  yearOptions,
}: PlanningSearchDropdownProps) => (
  <Dropdown
    button
    labeled
    className="icon"
    text={isMandat
      ? `Projet courant sur l'exercice ${selectedYear} - ${selectedYear + 6}`
      : `Projet courant sur l'exercice ${selectedYear}`}
  >
    <Dropdown.Menu>
      <Dropdown.Header icon="calendar" content="Filtrer par année" />
      <Dropdown.Divider />
      {yearOptions.map((option) => (
        <Dropdown.Item
          key={option.key}
          {...option}
          onClick={onYearChange}
        />
      ))}
      <Dropdown.Header icon="calendar" content="Filtrer par mandature" />
      <Dropdown.Divider />
      {mandatOptions.map((option) => (
        <Dropdown.Item
          key={option.key}
          {...option}
          onClick={onMandatChange}
        />
      ))}
    </Dropdown.Menu>
  </Dropdown>
);

export default PlanningSearchDropdown;
