export enum ProjetStatus {
    CONCEPTION = 1,
    PLANIFICATION = 2,
    EXECUTION = 3,
    CLOTURE = 4,
}

export const PROJET_STATUS_OPTIONS = [
  { key: ProjetStatus.CONCEPTION, value: ProjetStatus.CONCEPTION, text: 'Conception' },
  { key: ProjetStatus.PLANIFICATION, value: ProjetStatus.PLANIFICATION, text: 'Planification' },
  { key: ProjetStatus.EXECUTION, value: ProjetStatus.EXECUTION, text: 'Exécution' },
  { key: ProjetStatus.CLOTURE, value: ProjetStatus.CLOTURE, text: 'Clôturé' },
];
