// @ts-nocheck

import React from 'react';
import { ListeTable } from '@jvs-group/jvs-mairistem-liste';
import { Projet } from '@/src/interfaces/projet';

interface ProjetListeTableProps {
  items: Array<Projet>,
  count: number,
  perPage: number,
  loading: boolean,
  onLoad: any,
  onSelect: any,
  onSort: any,
}

const ProjetListeTable = ({
  items,
  count,
  perPage,
  loading,
  onLoad,
  onSort,
  onSelect,
} : ProjetListeTableProps) => (
  <ListeTable
    items={items}
    loading={loading}
    count={count}
    perPage={perPage}
    onLoad={onLoad}
    onSort={onSort}
    onSelect={onSelect}
  >
    <ListeTable.Header>
      <ListeTable.Column
        name="libelle"
        width={6}
      >
        Libellé
      </ListeTable.Column>
    </ListeTable.Header>
    {(projet) => (
      <ListeTable.Row>
        <ListeTable.Cell name="libelle">{projet?.libelle}</ListeTable.Cell>
      </ListeTable.Row>
    )}
  </ListeTable>
);

export default ProjetListeTable;
